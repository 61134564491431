// import { GoogleAuthProvider } from "firebase/auth";
import firebase from 'firebase/app';
import { auth } from "./firebase";
// import { RemoteMongoClient, Stitch, StitchCredential, GoogleCredential } from 'mongodb-stitch-browser-sdk';

export const doSignInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider);
}

export const doSignInWithMS = () => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    return auth.signInWithPopup(provider);
}

// const signInWithStichGoogle = () => {
    // const client = Stitch.initializeAppClient('wim-tsqru');
    // const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('wim');
    // const cred = new GoogleCredential();
    // client.auth.loginWithCredential( StitchCredential()
    // . GoogleAuthProvider
// }

export const doSignOut = () => {
    auth.signOut();
    window.location.pathname = "/signin";
}

export const isAuthed = () => !!auth.currentUser;

