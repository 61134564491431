import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
    apiKey: "AIzaSyCS0-_WhfgTT105bc9PbNGpSfMVhXDVSHs",
    authDomain: "wyzetalk-app-info.firebaseapp.com",
    databaseURL: "https://wyzetalk-app-info.firebaseio.com",
    projectId: "wyzetalk-app-info",
    storageBucket: "wyzetalk-app-info.appspot.com"
    // messagingSenderId: "487764056716"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const db = firebase.database();
export type gooleAuth = firebase.auth.GoogleAuthProvider;