import * as React from "react";
import { withRouter } from "react-router-dom";
import { SignInForm } from "./SignInForm";
import { Row, Col } from 'reactstrap';
// import logo from '../../assets/img/logo.png';

const SignInComponent = ({ history }: { [key: string]: any }) => (
    <div className="container mt-5">
        <Row>
            <Col xs="3">
                <img alt="logo" src="/img/logo.png" className="logo" />
            </Col>
            <Col>
                <h1>Wyzetalk</h1>
                <h2>WARM</h2>
                <p>You need to sign in</p>
                <SignInForm history={history} />
            </Col>
        </Row>


    </div>
);

export const SignIn = withRouter(SignInComponent);
