import { db } from './firebase';

const rememberState = async (uid: string, path: string, val: any, asJson = false) => {
    
    // must have a userId
    if (!uid) return;
    // don't stored backslashes
    if (val && typeof val === 'string' && val.includes("\\")) return;

    if (!asJson && typeof val === 'object') {
        val = JSON.stringify(val);
    }
    return db.ref(`v4/userState/${uid}/${path}`)
        // .child(key)
        .set(val)
        .catch(err => {
            console.log(err);
            // return Promise.resolve();
        })
}

const recallState = async (uid: string, path: string) => {
    // console.log("user.uid", user.uid);
    return db.ref(`v4/userState/${uid}/${path}`)
        .once('value')
        .then(snap => {
            if (snap) {
                return snap.val();
            } else {
                return;
            }
        });
}

export default {
    rememberState,
    recallState
};