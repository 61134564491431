import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { db } from "../../firebase/firebase";
import AppInstancesTable from './AppInstancesTable';
import * as objectPath from 'object-path';
import { FaRunning } from 'react-icons/fa';
import { AuthUserContext } from '../../firebase/AuthUserContext';
import userState from '../../firebase/user-state';

interface IUserChoices {
    sortBy: string;
    sortDir: number;
    filterText: string;
    filterData: string;
}

export const AppInstances = () => {
    const user = useContext(AuthUserContext);

    let appInstancesRef: firebase.default.database.Query;
    const dataPath: string = "/v4/instances";
    const list: any[] = [];

    const [sortDir, setSortDir] = useState(1);
    const [sortBy, setSortBy] = useState('appName');
    const [instances, setInstances] = useState(list);
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState({});
    const [showJson, setShowJson] = useState(false);

    const loadData = () => {
        if (appInstancesRef)
            appInstancesRef.off();

        appInstancesRef = db.ref(dataPath).orderByKey();

        appInstancesRef.on('value', snap => {
            const newList: any[] = [];
            if (snap) {
                snap.forEach(childSnapshot => {
                    newList.push({
                        key: childSnapshot.key,
                        ...childSnapshot.val()
                    })
                });
                setInstances(newList);
            }
        });
    }

    async function loadState() {
        userState.recallState(user.uid, 'apps').then((lastState: IUserChoices) => {
            if (lastState) {
                setSortBy(lastState.sortBy || 'appName');
                setSortDir(lastState.sortDir || 1);
                setFilterText(lastState.filterText || '');
                setFilterData(lastState.filterData ? JSON.parse(lastState.filterData) : {});
            }
        })
    }

    useEffect(() => {
        loadState().then(loadData);
        return () => {
            if (appInstancesRef)
                appInstancesRef.off();
        }
    }, []);

    const rememberChoice = (key: string, val: any) => {
        // if (val && && val. val.includes("\\")) return;
        return userState.rememberState(user.uid, `apps/${key}`, val);
    }

    const sortedList = () => {
        // filter first 
        let newList: any[] = [...instances];

        if (filterText) {
            newList = newList.filter(item => {
                return JSON.stringify(item).match(filterText);
            })
        }

        // filter by the keys in filterData
        const filteredKeys = Object.keys(filterData).filter(k => filterData[k]);
        if (filteredKeys.length > 0) {
            newList = newList.filter(item => {
                const found = filteredKeys.every(key => {
                    const itemValue = objectPath.get(item, key, '').toString();
                    if (itemValue) {
                        const filterValue = RegExp((filterData[key] || '').toString(), 'i');
                        return !!(itemValue.match(filterValue));
                    } else {
                        return false;
                    }
                });
                return found;
            })
        }

        newList.sort((a: any, b: any) => {
            const valA = objectPath.get(a, sortBy, '1');
            const valB = objectPath.get(b, sortBy, '2');
            return (valA > valB) ? sortDir : -sortDir;
        });

        return newList;
    }

    const onSort = (orderBy: string) => {
        if (sortBy === orderBy) {
            rememberChoice('sortDir', sortDir * -1);
            setSortDir(sortDir * -1);
        } else {
            rememberChoice('sortBy', orderBy);
            setSortBy(orderBy);
        }
    }

    const onFilter = (event: any) => {
        const val = event.target.value;
        rememberChoice('filterValue', val);
        setFilterText(val);
    }

    const onFilterFld = (event: any) => {
        const { name, value } = event.target;
        const newFilter = {
            ...filterData,
            [name]: value
        }
        //remove all the empty keys
        Object.keys(newFilter).forEach((key) => (!newFilter[key] && delete newFilter[key]));
        rememberChoice('filterData', newFilter);
        setFilterData(newFilter);
    }

    const deleteInstance = (epId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Delete instance')) return;
        db.ref(dataPath).child(epId).remove();
    }

    const toggleDetail = () => {
        setShowJson(!showJson);
    }

    return (
        <div className="ml-2 mr-2 mt-3">
            <h2> <FaRunning size="25" /> Apps Running</h2>
            <AppInstancesTable
                filterText={filterText}
                instances={sortedList()}
                sortBy={sortBy}
                sortDir={sortDir}
                onSort={(val: string) => onSort(val)}
                onDelete={(id: string) => deleteInstance(id)}
                onFilter={(e: any) => onFilter(e)}
                onFilterFld={(e: any) => onFilterFld(e)}
                filterData={filterData}
                showJson={showJson}
                toggleJson={toggleDetail}
            />
        </div>
    )
}