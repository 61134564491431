import * as React from "react";
import ReactJson from 'react-json-view'
import { Table, Button } from 'reactstrap';
import classnames from 'classnames';
import Moment from 'react-moment';

interface IProps {
    logs: any[];
    // sortBy: string;
    // sortDir: number;
    // onSort: (val: string) => void;
    // onFilter: (e: any) => void;
    // onFilterFld: (e: any) => void;
    // onDelete: (id: string) => void;
    toggleJson: () => void;
    showJson: boolean;
}

const appLogTable = ((props: IProps) => (
    <div className="m-3">
        <Table size="sm" bordered={true} striped={true} hover={true} className="smaller">
            <thead>
                <tr>
                    <th>Stage</th>
                    <th>App</th>
                    <th>File</th>
                    <th>Port</th>
                    <th>bV</th>
                    <th>Ver</th>
                    <th>Service</th>
                    <th>Host</th>
                    <th>Region</th>
                    <th>City</th>
                    <th>Mongo</th>
                    <th>Db</th>
                    <th>Started</th>
                    <th>Node</th>
                    {/* <th>Branch</th> */}
                    {/* <th>Src Date</th> */}
                    {/* <th>Src User</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    props.logs.map((log: any, id: number) => {
                        return <tr key={id} className="smaller">
                            <td className={classnames('stage', log.stage)}>{log.stage}</td>
                            <td>{log.appName}</td>
                            <td title={log.dirName}>{log.fileName.replace(/\.js$/, '')}</td>
                            <td>{log.port}</td>
                            <td>{log.bigV}</td>
                            <td>{log.appVersion}</td>
                            <td>{log.appService}</td>
                            <td>{log.host}</td>
                            <td>{log.ipInfo && log.ipInfo.region_code}</td>
                            <td>{log.ipInfo && log.ipInfo.city}</td>
                            <td>{log.mongoBase}</td>
                            <td>{log.dbNamePrefix}</td>
                            <td>
                                <Moment fromNow={true} ago={true}>{log.date}</Moment>
                            </td>
                            <td>{log.nodeVer}</td>
                            {/* <td>{log.git ? log.git.branch : null}</td> */}
                            {/* <td>{(log.git && log.git.date) ? <Moment fromNow={true} ago={true}>{log.git.date}</Moment> : null}</td> */}
                            {/* <td className="smaller">{(log.git && log.git.user) ? log.git.user.replace(/<.*>/, '') : null}</td> */}
                        </tr>
                    })
                }
            </tbody>
        </Table>
        <Button size="sm" onClick={props.toggleJson}>{props.showJson ? 'Hide ' : 'Show '} Data</Button>
        {props.showJson && <div>
            <hr />
            <ReactJson src={props.logs} />
        </div>
        }
    </div>
));

export default appLogTable