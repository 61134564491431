import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as routes from "../constants/routes";
import { withAuthentication } from "../firebase/withAuthentication";
import { Account } from "../pages/Account/Account";
import { AppLogs } from '../pages/AppLogs/AppLogs';
import { AppInstances } from '../pages/AppInstances/AppInstances';
import { EndPoints } from '../pages/EndPoints/EndPoints';
import { Home } from "../pages/Home/Home";
import { SignIn } from "../pages/SignIn/SignIn";
import { NavMenu } from "./NavMenu";
import * as moment from 'moment/moment';
import VersionSearch from '../pages/Urls/VersionSearch';
import VersionGrid from '../pages/Urls/VersionGrid';
// import Moment from 'react-moment';

interface IProps {
    authUser: any;
}

interface IState {
    authUser: any;
}

class AppComponent extends React.Component<IProps> {
    state: IState = {
        authUser: null
    }

    constructor(props: IProps) {
        super(props);

        // Moment.globalMoment = moment;
        moment.updateLocale('en', {
            'relativeTime': {
                future: 'in %s',
                past: "%s ago",
                s: 'just now',
                ss: '%d secs',
                m: "1 min",
                mm: "%d min",
                h: "1 hr",
                hh: "%d hrs",
                d: "a day",
                dd: "%d days",
                M: "1 mnth",
                MM: "%d mnths",
                y: "1 yr",
                yy: "%d years"
            }
        });
    }

    static getDerivedStateFromProps({ authUser }: IProps) {
        return {
            authUser,
        }
    }

    protectedRoute = (Component: any) => {
        // this.props.route = route;
        return this.state.authUser ? (
            <div><NavMenu />
                <Component authUser={this.state.authUser} />
            </div>
        ) : <Redirect to={routes.SIGN_IN} />;
    }

    public render() {
        if (!this.state.authUser) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact={true} path={routes.HOME} component={SignIn} />
                        <Route exact={true} path={routes.SIGN_IN} component={SignIn} />
                    </Switch>
                </BrowserRouter>
            )
        }

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact={true} path={routes.SIGN_IN} component={SignIn} />
                    <Route render={() => (this.protectedRoute(Account))} exact={true} path={routes.ACCOUNT} />
                    <Route render={() => (this.protectedRoute(Home))} exact={true} path={routes.HOME} />
                    <Route render={() => (this.protectedRoute(AppInstances))} exact={true} path={routes.APPINSTANCES} />
                    <Route render={() => (this.protectedRoute(AppLogs))} exact={true} path={routes.APPLOGS} />
                    <Route render={() => (this.protectedRoute(VersionSearch))} exact={true} path={routes.VERSION_SEARCH} />
                    <Route render={() => (this.protectedRoute(VersionGrid))} exact={true} path={routes.VERSION_GRID} />
                    <Route render={() => (this.protectedRoute(EndPoints))} exact={true} path={routes.ENDPOINTS} />
                </Switch>
            </BrowserRouter>
        );
        // }
    }
}

export const App = withAuthentication(AppComponent);