import * as React from "react";
import {
    Nav, NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from 'classnames';

interface IProps {
    stages : string[];
    clients?: string[];
    groups?: string[];
    activeTab: string;
    activeStage: string;
    activeClient: string;
    activeAppGroup: string;
    selectFilter: (name:string, val: any) => void;
    toggleTab: (tab: string) => void;
}

export const FilterTypes = ((props: IProps) => (
    <div>
        <Nav tabs={true}>
            <NavItem>
                <NavLink
                    className={classnames({ active: props.activeTab === 'stage' })}
                    onClick={() => { props.toggleTab('stage'); }}>
                    By Stage
                </NavLink>
            </NavItem>
            {props.clients && <NavItem>
                <NavLink
                    className={classnames({ active: props.activeTab === 'client' })}
                    onClick={() => { props.toggleTab('client'); }} >
                    By Client
                </NavLink>
            </NavItem>
            }
            {props.groups && <NavItem>
                <NavLink
                    className={classnames({ active: props.activeTab === 'group' })}
                    onClick={() => { props.toggleTab('group'); }} >
                    By Group
                </NavLink>
            </NavItem>
            }
        </Nav>

        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="stage" className="mt-3">
                <Nav pills={true}>
                    {props.stages.map((s: string) => {
                        return <NavItem key={s}>
                            <NavLink
                                className={classnames({ active: props.activeStage === s })}
                                onClick={() => { props.selectFilter('stage', s); }}
                            >
                                {s}
                            </NavLink>
                        </NavItem>
                    })}
                </Nav>
            </TabPane>
            <TabPane tabId="client" className="mt-3">
                <Nav pills={true}>
                    {(props.clients || []).map((s: string) => {
                        return <NavItem key={s}>
                            <NavLink
                                className={classnames({ active: props.activeClient === s })}
                                onClick={() => { props.selectFilter('client', s); }}
                            >
                                {s}
                            </NavLink>
                        </NavItem>
                    })}
                </Nav>
            </TabPane>
            <TabPane tabId="group" className="mt-3">
                <Nav pills={true}>
                    {(props.groups || []).map((s: string) => {
                        return <NavItem key={s}>
                            <NavLink
                                className={classnames({ active: props.activeAppGroup === s })}
                                onClick={() => { props.selectFilter('appGroup', s); }}
                            >
                                {s}
                            </NavLink>
                        </NavItem>
                    })}
                </Nav>
            </TabPane>
        </TabContent>
    </div>

));