import * as React from "react";
import Input from 'reactstrap/lib/Input';

interface IProps {
    fldName: string;
    value: any;
    onFilterItem: (e: any) => void;
    style?: any
}

const filterField = (props: IProps) => {
    return (
        <Input
            type="text"
            name={props.fldName}
            value={props.value || ''}
            onChange={props.onFilterItem}
            style={props.style}
            className={'filter-text'}
        />
    );
}


export default filterField