import * as React from "react";
import * as routes from "../../constants/routes";
import firebase from "firebase/app";
import { auth } from "../../firebase";
import { Button } from 'reactstrap';
// import { UserApiKeyCredential, Stitch } from 'mongodb-stitch-browser-sdk';

interface InterfaceProps {
    email?: string;
    error?: any;
    history?: any;
    password?: string;
}

interface InterfaceState {
    email: string;
    error: any;
    password: string;
}

export class SignInForm extends React.Component<
    InterfaceProps,
    InterfaceState
    > {
    private static INITIAL_STATE = {
        email: "",
        error: null,
        password: ""
    };

    private static propKey(propertyName: string, value: any): object {
        return { [propertyName]: value };
    }

    constructor(props: InterfaceProps) {
        super(props);
        this.state = { ...SignInForm.INITIAL_STATE };
    }

    public onLogin = (provider: 'Google' | 'Microsoft') => {
        const { history } = this.props;

        let signIn;
        switch (provider) {
            case "Google":
                signIn = auth.doSignInWithGoogle;
                break;
            case "Microsoft":
                signIn = auth.doSignInWithMS;
                break;
        }

        signIn()
            .then((creds: firebase.auth.UserCredential) => {
                // const apiKey = 'ULNh0SmE8CKUvlOILkbjrsxwQT410jCTIcbaV39Yu8nmVYXf13N4jHVe2pPef6ky';
                // const credential = new UserApiKeyCredential(apiKey)
                console.log("[SignInForm: creds]", creds);

                // return Stitch.defaultAppClient.auth.loginWithCredential(credential)
                //     .then(authedId => {
                //         console.log(`successfully logged in with id: ${authedId}`);
                        this.setState(() => ({ ...SignInForm.INITIAL_STATE }));
                        history.push(routes.HOME);
                    // })
                    // .catch(err => {
                    //     console.error(`login failed with error: ${err}`)
                    // });


            })
            .catch(error => {
                console.log("[SignInForm: error]", error);
                this.setState(SignInForm.propKey("error", error));
            });

    };

    public render() {
        return (
            <div className="login-choices">
                <Button className="login-button" color="secondary" onClick={() => this.onLogin('Microsoft')}>
                    <img className="login-logo" src="/img/microsoft.png" alt="Microsoft"/>
                    Sign In (Microsoft) wyzetalk.com</Button>
                <br/>
                <br/>
                <Button className="login-button" color="primary" onClick={() => this.onLogin('Google')}>
                <img className="login-logo" src="/img/google.png" alt="Google"/>
                    Old Sign In (Google) wyzetalk.com</Button>
            </div>
        );
    }

}