import * as React from "react";
import { AuthUserContext } from "../../firebase/AuthUserContext";
import { withAuthorization } from "../../firebase/withAuthorization";

export const AccountComponent = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            <div className="container center">
                <h1><strong>W</strong>yzetalk <strong>A</strong>pplication <strong>R</strong>esource <strong>M</strong>anager</h1>
                <p>Hi there, {(authUser as any).displayName}</p>
                {authUser.photoURL && <img alt={'Profile'} src={(authUser as any).photoURL} style={{width:'60px', height:'auto'}} />}
                <p>Welcome to WARM. </p>
                <hr/>
                {/* You are logged in with: {JSON.stringify(authUser)} */}
            </div>
        )}
    </AuthUserContext.Consumer>
);

const authCondition = (authUser: any) => !!authUser;

export const Account = withAuthorization(authCondition)(AccountComponent);
