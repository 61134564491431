import * as React from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Card, CardTitle, CardText, Row, Col, Button } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import * as routes from "../../constants/routes";
import { FaLink, FaRunning, FaListAlt } from 'react-icons/fa';

class HomeComponent extends React.Component {
    constructor(props: any) {
        super(props);

        this.state = {
            users: null
        };
    }

    public componentDidMount() {
        db.onceGetUsers().then(snapshot =>
            this.setState(() => ({ users: snapshot.val() }))
        );
    }

    public render() {
        // const { users }: any = this.state;

        return (
            <div className="container center mt-5">
                <h1><strong>W</strong>yzetalk <strong>A</strong>pplication <strong>R</strong>esource <strong>M</strong>anager</h1>
                <h3>Welcome to WARM</h3>
                <h6>Ver: {process.env.REACT_APP_VERSION}</h6>
                <p>Here, we'll be cooking with gas.</p>

                <Row>
                    <Col sm="4">
                        <Card inverse={true} color="info">
                            <CardBody>
                                <CardTitle>End Points</CardTitle>
                                <CardText> All the urls we manage </CardText>
                                <Button tag={Link} to={routes.ENDPOINTS}>
                                    <FaLink size="25" /> Go</Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="4">
                        <Card inverse={true} color="info">
                            <CardBody>
                                <CardTitle>Apps</CardTitle>
                                <CardText> All the apps running </CardText>
                                <Button tag={Link} to={routes.APPINSTANCES}>
                                    <FaRunning size="25" /> Go</Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="4">
                        <Card inverse={true} color="info">
                            <CardBody>
                                <CardTitle>Logs</CardTitle>
                                <CardText> As and when it happen </CardText>
                                <Button tag={Link} to={routes.APPLOGS}>
                                    <FaListAlt size="25" /> Go</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}

const authCondition = (authUser: any) => !!authUser;

export const Home = withAuthorization(authCondition)(HomeComponent);