import * as React from "react";
import ReactJson from 'react-json-view'
import { Table, Input, Button } from 'reactstrap';
import classnames from 'classnames';
import Moment from 'react-moment';
import SortHeader from '../../components/SortHeader';
import FilterField from '../../components/FilterField';

interface IProps {
    filterText: string;
    filterData: any;
    instances: any[];
    sortBy: string;
    sortDir: number;
    onSort: (val: string) => void;
    onFilter: (e: any) => void;
    onFilterFld: (e: any) => void;
    onDelete: (id: string) => void;
    toggleJson: () => void;
    showJson: boolean;
}

const appLogTable = (props: IProps) => {

    const columnOpts = [
        { filter: true, key: "no", sort: false, text: '', name: '' },
        { filter: true, key: "clusterCode", sort: true, text: 'Clust', name: 'clusterCode', style: { width: '50px'} },
        { filter: true, key: "stage", sort: true, text: 'Stage', name: 'stage', style: { width: '70px'} },
        { filter: true, key: "clientCode", sort: true, text: 'Client', name: 'clientCode', style: { width: '60px'}  },
        { filter: true, key: "appName", sort: true, text: 'App/Project', name: 'appName' },
        { filter: true, key: "fileName", sort: true, text: 'Exec File', name: 'fileName' },
        { filter: true, key: "port", sort: true, text: 'Port', name: 'port', style: { width: '50px' }, btnClass: "center" },
        { filter: true, key: "appVersion", sort: true, text: 'Ver', name: 'appVersion', style: { width: '65px' } },
        { filter: true, key: "buildDate", sort: true, text: 'Build', name: 'buildDate', style: { width: '65px' } },
        { filter: true, key: "appService", sort: true, text: 'Service', name: 'appService' },
        { filter: true, key: "host", sort: true, text: 'Host', name: 'host' },
        { filter: true, key: "country", sort: true, text: 'Ctry', name: 'ipInfo.country', style: { width: '40px' } },
        { filter: true, key: "regionCode", sort: true, text: 'Reg', name: 'ipInfo.region_code', style: { width: '40px' } },
        { filter: true, key: "city", sort: true, text: 'City', name: 'ipInfo.city' },
        { filter: true, key: "mongoBase", sort: true, text: 'Mongo Cluster', name: 'mongoBase' },
        { filter: true, key: "dbNamePrefix", sort: true, text: 'wt_{DB}', name: 'dbNamePrefix', style: { width: '70px' } },
        { filter: false, key: "date", sort: true, text: 'Start', name: 'date',  style: { width: '50px' } },
        { filter: false, key: "lastDate", sort: true, text: 'Seen', name: 'lastDate', style: { width: '50px'} },
        { filter: false, key: "requestCnt", sort: true, text: 'Req', name: 'requestCnt', style: { width: '50px' }, btnClass: "center" },
        { filter: false, key: "lastRequest", sort: true, text: 'Last', name: 'lastRequest', style: { width: '50px'} },
        { filter: true, key: "nodeVer", sort: true, text: 'Node', name: 'nodeVer', style: { width: '50px'} },
    ];

    const ignoreColumns = {
        // 'mongoBase': true,
        'branch': true,
        'srcDate': true,
        'srcUser': true
    };
    const columnDefs = columnOpts.filter(c => !ignoreColumns[c.key]);

    return (
        <div className="m-3">
            <Input type="text" value={props.filterText} placeholder="Quick filter" onChange={(e) => props.onFilter(e)} />
            <Table size="sm" bordered={true} striped={true} hover={true} className="smaller">
                <thead>
                    <tr>
                        {columnDefs.map((f: any, i: number) => {
                            return (
                                <th key={`ftr-${i}`}>
                                    {f.name && f.filter && <FilterField
                                        fldName={f.name}
                                        value={props.filterData[f.name]}
                                        onFilterItem={props.onFilterFld}
                                        style={f.style}
                                    />}
                                </th>
                            )
                        })}
                       <th>&nbsp;</th>
                    </tr>
                    <tr>
                        {columnDefs.map((f: any, i: number) => {
                            return (
                                <th key={`ftr-${i}`} className={f.btnClass}>
                                    {f.sort ?
                                        <SortHeader text={f.text}
                                            fldName={f.name}
                                            sortBy={props.sortBy}
                                            sortDir={props.sortDir}
                                            title={f.toolTip || f.name}
                                            onHeaderSort={(val: string) => props.onSort(val)} />
                                        : <span>{f.text}</span>
                                    }
                                </th>
                            )
                        })}
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.instances.map((log: any, id: number) => {
                            return <tr key={id}>
                                <td style={{ textAlign: 'center' }}>{id + 1}</td>
                                <td>{log.clusterCode}</td>
                                <td className={classnames('stage', log.stage)}>{log.stage}</td>
                                <td>{log.clientCode}</td>
                                <td>{log.appName || log.key}</td>
                                <td className="smaller" title={log.dirName}>{(log.fileName || '').replace(/\.js$/, '')}</td>
                                <td className='center'>{log.port}</td>
                                <td>{log.appVersion}</td>
                                <td>
                                    {log.buildDate && <Moment withTitle={log.buildDate} fromNow={true} ago={true}>{log.buildDate}</Moment>}
                                </td>
                                <td className="smaller">{log.appService}</td>
                                <td className="smaller">{log.host}</td>
                                <td>{log.ipInfo && log.ipInfo.country}</td>
                                <td>{log.ipInfo && log.ipInfo.region_code}</td>
                                <td>{log.ipInfo && log.ipInfo.city}</td>
                                {!ignoreColumns['mongoBase'] && <td className="smaller">{log.mongoBase}</td>}
                                <td>{log.dbNamePrefix}</td>
                                <td>
                                    {log.date && <Moment fromNow={true} ago={true}>{log.date}</Moment>}
                                </td>
                                <td>
                                    {log.lastDate && <Moment fromNow={true} ago={true}>{log.lastDate}</Moment>}
                                </td>
                                <td className='center'>{log.requestCnt}</td>
                                <td>
                                    {log.lastRequest && <Moment fromNow={true} ago={true}>{log.lastRequest}</Moment>}
                                </td>
                                <td>{log.nodeVer}</td>
                                {!ignoreColumns['branch'] && <td>{log.git ? log.git.branch : null}</td>}
                                {!ignoreColumns['srcDate'] && <td>{(log.git && log.git.date) ? <Moment fromNow={true} ago={true}>{log.git.date}</Moment> : null}</td>}
                                {!ignoreColumns['srcUser'] && <td className="smaller">{log.git && log.git.user ? log.git.user.replace(/<.*>/, '') : null}</td>}
                                <td>
                                    <button onClick={() => props.onDelete(log.key)}>D</button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            <Button size="sm" onClick={props.toggleJson}>{props.showJson ? 'Hide ' : 'Show '} Data</Button>
            {props.showJson && <div>
                <hr />
                <ReactJson src={props.instances} />
            </div>
            }
        </div>
    );
};

export default appLogTable