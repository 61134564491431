import * as React from "react";
import { firebase } from "../firebase";
import { AuthUserContext } from "./AuthUserContext";
import {
    // RemoteMongoClient,
    Stitch,
    // StitchCredential,
    // GoogleRedirectCredential,
    // GoogleCredential,
    // UserApiKeyCredential,
    AnonymousCredential,
} from 'mongodb-stitch-browser-sdk';

const client = Stitch.initializeAppClient('wim-tsqru');

interface InterfaceProps {
    authUser?: any;
    history?: any;
}

interface InterfaceState {
    authUser?: any;
}

export const withAuthentication = (Component: any) => {
    class WithAuthentication extends React.Component<
        InterfaceProps,
        InterfaceState
        > {
        constructor(props: any) {
            super(props);

            this.state = {
                authUser: null
            };
        }

        public componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (authUser) {
                    // console.log(['with Authentication.componentDidMount | logged in']);
                    console.log("onAuthStateChanged: authUser:", authUser);
                    // microsoft data is in another place
                    const providerId = authUser.providerData[0]?.providerId;
                    const email = providerId === 'google.com' ? authUser.email : authUser.providerData[0]?.email;
                    if (!/wyzetalk\.com$/.test(email || '')) {
                        firebase.auth.signOut();
                        return;
                    }
                    authUser.getIdTokenResult().then(tokenResult => {
                        console.log("tokenResult\n", tokenResult);
                    })
                    authUser.getIdToken().then(token => {
                        this.signInWithStichGoogle(token);
                    })
                    firebase.db.ref(`v4/users/${authUser.uid}`).set({
                        displayName: authUser.displayName,
                        lastSeen: new Date().toISOString(),
                        name: email,
                        photoUrl: authUser.photoURL,
                        providerId: providerId
                    });
                } else {
                    // console.log("Not logged in");
                    // window.location.pathname = routes.SIGN_IN;
                }
                authUser
                    ? this.setState(() => ({ authUser }))
                    : this.setState(() => ({ authUser: null }));
            });
        }

        signInWithStichGoogle = (token: string) => {
            // const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('wim');
            // const cred = new GoogleRedirectCredential();
            // const cred = new UserApiKeyCredential('caArODP5o8C4PxHNJvEdUWci9GY0M036wbUc9vMeQ57MVlFFlF1CcjYjzamsNddY');
            const cred = new AnonymousCredential();
            client.auth.loginWithCredential(cred).then(stitchUser => {
                // console.log("stitchUser", stitchUser);
            }).catch(err => {
                console.error("stichError", err);
            })
        }

        public render() {
            const { authUser } = this.state;

            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component authUser={authUser} />
                </AuthUserContext.Provider>
            );
        }
    }
    return WithAuthentication;
};