import * as React from "react";
import { Button, Badge } from 'reactstrap';
interface IProps {
    fldName: string;
    text: string;
    title?: string;
    sortBy: string;
    sortDir: number;
    onHeaderSort: (fldName: string) => void;
}

const SortHeader = ((props: IProps) => (
    <Button size="sm" color="primary" outline={true} onClick={() => props.onHeaderSort(props.fldName)}
        title={props.title}>
        {props.text}
        {props.sortBy === props.fldName ?
            <Badge pill={true} color="secondary">{props.sortDir === 1 ? <span>&darr;</span> : <span>&uarr;</span>}</Badge>
            : ''}
    </Button>
));


export default SortHeader