import * as React from "react";
import ReactJson from 'react-json-view'
import { Table, Input, Button } from 'reactstrap';
import Moment from 'react-moment';
import classnames from 'classnames';
import SortHeader from '../../components/SortHeader';
import FilterField from '../../components/FilterField';

interface IProps {
    filterData: any;
    onFilterFld: (e: any) => void;
    list: any[];
    sortBy: string;
    sortDir: number;
    onSort: (val: string) => void;
    onDelete: (id: string) => void;
    onCheck: (id: string) => void;
    infos: any;
    onInfo: (id: string) => void;
    toggleJson: () => void;
    showJson: boolean;
}

const endPointsTable = (props: IProps) => {
    const endPoints: any[] = props.list;
    let lineNo: number = 0;

    return (
        <div className="p-3">
            <Table size="sm" striped={true} bordered={true} hover={true} className="smaller">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th style={{maxWidth: '40px'}}>
                            <FilterField fldName="stage" value={props.filterData.stage} onFilterItem={props.onFilterFld} />
                        </th>
                        <th style={{ maxWidth: '80px' }}>
                            <FilterField fldName="appGroup" value={props.filterData.appGroup} onFilterItem={props.onFilterFld} />
                        </th>
                        <th style={{ maxWidth: '40px' }}>
                            <FilterField fldName="category" value={props.filterData.category} onFilterItem={props.onFilterFld} />
                        </th>
                        <th style={{ maxWidth: '60px' }}>
                            <FilterField fldName="client" value={props.filterData.client} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>
                            <FilterField fldName="url" value={props.filterData.url} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>
                            <FilterField fldName="get" value={props.filterData.get} onFilterItem={props.onFilterFld} />
                        </th>
                        {/* <th>&nbsp;</th> */}
                        <th style={{ maxWidth: '40px' }}>
                            <FilterField fldName="check" value={props.filterData.check} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>&nbsp;</th>
                        <th style={{ maxWidth: '80px' }}>
                            <FilterField fldName="checkRes.gateway" value={props.filterData['checkRes.gateway']} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>
                            <FilterField fldName="checkRes.appService" value={props.filterData['checkRes.appService']} onFilterItem={props.onFilterFld} />
                        </th>
                        <th style={{ maxWidth: '60px' }}>
                            <FilterField fldName="checkRes.host" value={props.filterData['checkRes.host']} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>
                            <FilterField fldName="checkRes.name" value={props.filterData['checkRes.name']} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>
                            <FilterField fldName="checkRes.version" value={props.filterData['checkRes.version']} onFilterItem={props.onFilterFld} />
                        </th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>
                            <SortHeader text='Stage' fldName='stage' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Grp' fldName='appGroup' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Cat' fldName='category' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Client' fldName='client' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Url' fldName='url' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Get' fldName='get' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        {/* <th>Phase</th> */}
                        <th>
                            <SortHeader text='Chk' fldName='check' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Checked' fldName='checkDate' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='GW' fldName='checkRes.gateway' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Service' fldName='checkRes.appService' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Host' fldName='checkRes.host' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='App' fldName='checkRes.name' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>
                            <SortHeader text='Ver' fldName='checkRes.version' sortBy={props.sortBy} sortDir={props.sortDir} onHeaderSort={(val: string) => props.onSort(val)} />
                        </th>
                        <th>Uptime</th>
                        <th>*</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        endPoints.map((ep: any, id: number) => {
                            return <tr key={ep.key}>
                                <td>{++lineNo}</td>
                                <td className={classnames('stage', ep.stage)}>{ep.stage}</td>
                                <td>{ep.appGroup}</td>
                                <td>{ep.category}</td>
                                <td>{ep.client}</td>
                                <td className="smaller">{ep.url}
                                    {props.infos[ep.key] && <div>
                                        <div>{ep.key}</div>
                                        <Input type="textarea"
                                            style={{ width: '100%', fontSize: '0.8em' }}
                                            defaultValue={ep.check === '200' ? JSON.stringify(ep.checkRes) : ep.checkRes.toString()} />
                                    </div>}
                                </td>
                                <td>{ep.get}</td>
                                {/* <td>
                                    {ep.status}
                                </td> */}
                                <td className={['check', `s${ep.check}`].join(" ")}>{ep.check}</td>
                                <td>
                                    {ep.checkNow ? 'checking...' : <Moment fromNow={true} ago={true}>{ep.checkDate}</Moment>}
                                </td>
                                <td>{ep.checkRes ? ep.checkRes.gateway : null}</td>
                                <td>{ep.checkRes ? ep.checkRes.appService : null}</td>
                                <td>{ep.checkRes ? ep.checkRes.host : null}</td>
                                <td>{ep.checkRes ? ep.checkRes.name : null}</td>
                                <td>{ep.checkRes ? ep.checkRes.version : null}</td>
                                <td>{ep.checkRes ? ep.checkRes.upTime : null}</td>
                                <td>
                                    <button onClick={() => props.onDelete(ep.key)}>D</button>
                                    <button onClick={() => props.onCheck(ep.key)}>C</button>
                                    <button onClick={() => props.onInfo(ep.key)}>I</button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            <Button size="sm" onClick={props.toggleJson}>{props.showJson ? 'Hide ' : 'Show '} Data</Button>
            {props.showJson && <div>
                <hr />
                <ReactJson src={props.list} />
            </div>
            }
        </div>
    );
};

export default endPointsTable;