import * as React from "react";
import firebase from "firebase/app";

const firebaseUser: firebase.UserInfo = {
    uid: '',
    displayName: null,
    email: null,
    phoneNumber: null,
    photoURL: null,
    providerId: ''

};
export const AuthUserContext = React.createContext(firebaseUser);
