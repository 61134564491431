import firebase from "firebase/app";
import "firebase/functions";

export async function sayHallo() {

    const helloJson = firebase.functions().httpsCallable('helloJson');
    helloJson().then(function (result) {
        // Read result of the Cloud Function.
        // var sanitizedMessage = result.data.text;
        return result;
    });
}
