import * as React from "react";
import { Alert } from 'reactstrap';

export const Loading = () => (

    <div className="container center mt-4">
        <Alert color="secondary">
            Loading...
        </Alert>
    </div>
);