import * as React from "react";
import { useState, useEffect, ReactNode, useContext } from 'react';
// import { db } from "../../firebase/firebase";

// import ReactJson from 'react-json-view';
import Moment from 'react-moment';
import Select from 'react-select';
// import { selectStyles, IndicatorSeparator } from './select-styles';
import { styles } from 'react-select-bootstrap3';


import {
    appGroups,
    categories,
    searchUrls,
    clients,
    ecMappings,
    checkUrl,
} from '../../stitch';

import {
    IUrlQry,
} from '../../stitch/interfaces';

import { Button, Table, PopoverHeader, PopoverBody, UncontrolledPopover, Label, Input, FormGroup, Form, Row, Container } from 'reactstrap';
import { FaSearch, FaSync } from 'react-icons/fa';
import * as objectPath from 'object-path';
import { AuthUserContext } from 'src/firebase/AuthUserContext';
import userState from 'src/firebase/user-state';

interface IClientData {
    [key: string]: {
        name: string;
        versions: {
            [key: string]: {
                urls: IUrl[]
            }
        }
    }
}

interface IUrl {
    appService: string;
    get: string;
    host: string;
    key: string;
    padVersion: string;
    reqCnt: number;
    reqLast: string;
    stage: string;
    startDate: string;
    url: string;
    version: string;
    checkRes?: any
    checkInfo?: {
        checkEnd: string;
        checkStart: string
    }
}

interface IViewOpts {
    clients: string[];
    stage: string;
    appGroup: string;
    category: string;
    showItemHost: boolean;
    showCheckDate: boolean;
    showItemUrl: boolean;
    showAppService: boolean;
    showStartDate: boolean;
}

interface ICheckStatus {
    current: number;
    total: number;
    rate: number;
}

const VersionGrid = () => {
    const USER_STATE_KEY = 'version-grid';
    const user = useContext(AuthUserContext);

    const stageList = ['', 'prod', 'beta', 'uat', 'test'];
    const [appGroupList, setAppGroupList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [ecMapping, setEcMapping] = useState({});

    const [stage, setStage] = useState('prod');
    const [checkStatus, setCheckStatus] = useState<ICheckStatus>({
        current: 0,
        total: 0,
        rate: 0,
    });

    // const defList: Array<any> = [];
    const verList: Array<any> = [];
    const cItems: Array<any> = [];
    const uItems: Array<any> = [];

    // const [urlList, setUrlList] = useState(defList);
    const [versionList, setVersionList] = useState(verList);
    const [clientItems, setClientItems] = useState(cItems);
    const [clientData, setClientData] = useState<IClientData>({});

    const [urls, setUrls] = useState(uItems);

    const defaultViewOpts: IViewOpts = {
        stage: 'prod',
        appGroup: 'member',
        category: 'api',
        clients: [],
        showItemHost: false,
        showCheckDate: false,
        showItemUrl: false,
        showAppService: false,
        showStartDate: false,
    }

    const [viewOpts, setViewOpts] = useState<IViewOpts>(defaultViewOpts);

    const onSelectedChange = (vals: any) => {
        // console.log("onSelectedChange", e);
        const newViewOpts = {
            ...viewOpts,
            clients: vals
        }
        setViewOpts(newViewOpts);
    }

    const onViewOptsChange = (e: any) => {
        const target = e.target;
        let value: any = '';
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            default:
                value = target.value;
        }
        const name = target.name || target.id;
        const newViewOpts = {
            ...viewOpts,
            [name]: value
        }
        setViewOpts(newViewOpts)
    }

    // async function loadState() {
    //     userState.recallState(user.uid, USER_STATE_KEY, 'viewOpts').then(d => {
    //         if (d) setViewOpts(d);
    //     })
    // }

    useEffect(() => {

        const loadLists = async () => {
            const blankItem = { _id: '' };

            const agList: any = await appGroups.find({}, { sort: { '_id': 1 } }).toArray();
            const catList: any = await categories.find({}, { sort: { '_id': 1 } }).toArray();
            const clList: any = await clients.find({}, { sort: { '_id': 1 } }).toArray();
            const ecList: any = await ecMappings.find({}, { sort: { '_id': 1 } }).toArray();
            // const userState = await getUserState()
            // setViewOpts({
            //     ...viewOpts,
            //     appGroup: 'member'
            // })
            agList.unshift(blankItem);
            catList.unshift(blankItem);
            // clList.push({ _id: '' });
            setAppGroupList(agList);
            setCategoryList(catList);
            const selectList = clList.map((c: any) => { return { value: c._id, label: c._id } });
            setClientList(selectList);
            const ecMaps = {};
            ecList.forEach((ec: any) => {
                ecMaps[ec._id] = ec.map;
            })
            setEcMapping(ecMaps);
        }

        userState.recallState(user.uid, `${USER_STATE_KEY}/viewOpts`).then(d => {
            if (d) setViewOpts(d);
            // })
            // loadState().then(() => {
            loadLists();
        })
    }, [user]);

    const rememberChoice = (key: string, val: any) => {
        return userState.rememberState(user.uid, `${USER_STATE_KEY}/${key}`, val, true);
    }

    const onSearch = async () => {
        const csvClients: string = viewOpts.clients ? viewOpts.clients.map((c: any) => c.value).join(",") : "";

        const qry: IUrlQry = {
            opts: {
                // limit: 0,
                sort: { 'client': 1, 'checkRes.padVersion': 1 },
                projection: {
                    key: 1,
                    client: 1,
                    url: 1,
                    get: 1,
                    stage: 1,
                    checkRes: 1,
                    checkInfo: 1
                }
            },
            'checkInfo.statusCode': 200,
            stage: stage,
            appGroup: viewOpts.appGroup,
            category: viewOpts.category,
            client: csvClients
        }
        rememberChoice("viewOpts", viewOpts);
        const list: any[] = await searchUrls(qry);
        buildGrid(list);
    }

    const buildGrid = (list: any[]) => {
        const vers: string[] = [];
        const clients: string[] = [];
        const cData: IClientData = {};
        list.forEach((url: any) => {
            unpackUrl(url, cData, vers, clients);
        });
        vers.sort();
        clients.sort();
        setUrls(list);
        setVersionList(vers);
        setClientItems(clients);
        setClientData(cData);
        setCheckStatus({ current: 0, total: 0, rate: 0 });
    }

    const unpackUrl = (url: IUrl, cData: IClientData, vers: any[], clients: any[]) => {
        const v = objectPath.get(url, 'checkRes.padVersion', '0.0.0') || '0.0.0';
        const c = objectPath.get(url, 'client', "any");

        objectPath.ensureExists(cData, [c, 'name'], c);
        objectPath.ensureExists(cData, [c, 'versions', v, 'urls'], []);

        const urlData: IUrl = {
            key: url.key,
            version: objectPath.get(url, ['checkRes', 'version'], ''),
            padVersion: v,
            url: url.url,
            get: url.get,
            appService: objectPath.get(url, ['checkRes', 'appService'], ''),
            host: objectPath.get(url, ['checkRes', 'host'], ''),
            stage: url.stage,
            startDate: objectPath.get(url, ['checkRes', 'startDate'], ''),
            reqCnt: parseInt(objectPath.get(url, ['checkRes', 'reqCnt'], '0')),
            reqLast: objectPath.get(url, ['checkRes', 'reqLast']),
            checkInfo: url.checkInfo
        }

        const urls = cData[c].versions[v].urls;
        if (urls.find(u => u.key === urlData.key)) {
            cData[c].versions[v].urls = urls.map(u => {
                return (u.key === urlData.key) ? urlData : u;
            })
        } else {
            cData[c].versions[v].urls.push(urlData);
        }

        if (!clients.includes(c)) {
            clients.push(c);
        }
        if (!vers.includes(v)) {
            vers.push(v);
        }

    }

    const onCheckUrl = async (url: any) => {
        checkUrl(url.key).then((res: IUrl) => {
            const vers: any[] = [...versionList];
            const clients: any[] = [...clientItems];
            const cData: IClientData = { ...clientData };
            unpackUrl(res, cData, vers, clients);
            vers.sort();
            clients.sort();
            setVersionList(vers);
            setClientItems(clients);
            setClientData(cData);
        })
    }

    const onCheckAll = async () => {
        const total: number = urls.length;
        let cnt = 1;
        setCheckStatus({ current: cnt, total, rate: 0 });
        const newList: any[] = [];
        const promises: Promise<any>[] = [];

        for (const u of urls) {
            const fn = async (cnt: number) => {
                const checked = await checkUrl(u.key);
                newList.push(checked);
                setCheckStatus({ current: cnt, total, rate: 0 });
            };
            promises.push(fn(cnt++));
        }
        await Promise.all(promises);
        buildGrid(newList);
    }

    const stageColorMappings = {
        test: "success",
        uat: "dark",
        beta: "info",
        prod: "warning"
    }

    const getStageColorMapping = (url: string, stage: string): string => {
        if (/standby/i.test(url)) {
            return 'secondary';
        } else {
            return stageColorMappings[stage] || 'danger';
        }
    }

    const mapUrls = (c: string, v: string): ReactNode | undefined => {
        if (!c && !v) return;

        const urls: any[] = objectPath.get(clientData, [c, 'versions', v, 'urls']);
        if (!urls || urls.length === 0) return;
        // console.log(c, v, urls);
        return urls.map((u: IUrl) => {
            const badgeColor = getStageColorMapping(u.url, u.stage);
            const pid = `u-${u.key.replace(/\./g, '-')}`;
            return (<div key={u.key}>
                {/* <Badge color={badgeColor}>{u.reqCnt}</Badge>&nbsp; */}
                <Button id={pid} color={badgeColor} size='sm' title={u.stage + ':' + u.reqCnt + ' Requests'}>{u.stage}</Button>&nbsp;
                ({u.reqCnt})&nbsp;

                <a href={u.url + u.get} title={u.url + u.get}>
                    {!viewOpts.showCheckDate && u.reqLast && <Moment fromNow={true} ago={true}>{u.reqLast}</Moment>}
                    {viewOpts.showCheckDate && u.checkInfo && <Moment fromNow={true} ago={true}>{u.checkInfo?.checkEnd}</Moment>}
                </a>
                {viewOpts.showItemUrl &&
                    <div><a href={u.url} target="_blank">{u.url}</a></div>
                }
                {viewOpts.showAppService && u.appService &&
                    <div>{u.appService}</div>
                }
                {viewOpts.showStartDate && u.startDate &&
                    <div>Started: <Moment fromNow={true} ago={false}>{u.startDate}</Moment></div>
                }
                {viewOpts.showItemHost && u.host &&
                    <div>{u.host}</div>
                }
                <UncontrolledPopover trigger="click" placement="bottom" target={pid}>
                    <PopoverHeader>{u.url}</PopoverHeader>
                    <PopoverBody>
                        <div>{u.get}</div>
                        <div>{u.version}</div>
                        {u.appService && <div>{u.appService}</div>}
                        {u.host && <div>{u.host}</div>}
                        {u.startDate && <div>Started: <Moment fromNow={true} ago={false}>{u.startDate}</Moment></div>}
                        {(u.reqCnt > 0) && <div>Requests: {u.reqCnt}</div>}
                        {u.reqLast && <div>Last Req: <Moment fromNow={true} ago={true}>{u.reqLast}</Moment></div>}
                        {u.checkInfo && <div>Last check: <Moment fromNow={true} ago={true}>{u.checkInfo.checkEnd}</Moment></div>}
                        {/* <ReactJson src={u} /> */}
                        <div>Key: {u.key}</div>
                        <Button id={pid + '2'} title='Hallo' size='sm' onClick={() => onCheckUrl(u)}>Check</Button>

                    </PopoverBody>
                </UncontrolledPopover>

            </div>);
        });

    }

    const cleanVersion = (padVersion: string) => {
        //convert "04.033.006" to 4.33.6;
        return padVersion.split(".").map(v => parseInt(v)).join(".");
    }

    const logoUrl = (c: string) => {
        const ec = ecMapping[c] || c;
        return `https://domain-cdn.wyzetalk.com/prod/domains/${ec}/logo.png`
    }

    return (
        <div className='ml-2 mr-2 mt-3'>
            <Container fluid={true}>

                <h1>Version Grid</h1>

                <Form inline={true}>
                    <Row form={true}>
                        <Select value={viewOpts.clients}
                            placeholder='Clients'
                            closeMenuOnSelect={false}
                            styles={styles}
                            options={clientList}
                            isMulti={true}
                            isSearchable={true}
                            onChange={v => onSelectedChange(v)} />
                        <FormGroup>
                            <Input type="select" onChange={e => setStage(e.target.value)} value={stage}>
                                {stageList && stageList.map((s: string) => { return (<option key={s}>{s}</option>) })}
                            </Input>
                        </FormGroup>
                        <Input type="select" name='appGroup' onChange={e => onViewOptsChange(e)} value={viewOpts.appGroup}>
                            {appGroupList && appGroupList.map((c: any) => { return (<option key={c._id}>{c._id}</option>) })}
                        </Input>
                        <Input type="select" name='category' onChange={e => onViewOptsChange(e)} value={viewOpts.category}>
                            {categoryList && categoryList.map((c: any) => { return (<option key={c._id}>{c._id}</option>) })}
                        </Input>


                        {(checkStatus.current === 0) && <Button onClick={() => onSearch()} title="Search for the apps">
                            <FaSearch />
                        </Button>}&nbsp;
                        {(urls.length > 0) && (checkStatus.current === 0) && <Button onClick={() => onCheckAll()} title="Check all the displayed app versions">
                            <FaSync />
                        </Button>
                        }
                        &nbsp;

                        {(checkStatus.current === 0) && <FormGroup className="grid-checks">
                            <Label>
                                <Input type="checkbox" name="showCheckDate" onChange={e => onViewOptsChange(e)} />{' '}
                            Check date
                            </Label>
                            <Label>
                                <Input type="checkbox" name="showItemUrl" onChange={e => onViewOptsChange(e)} />{' '}
                            Url
                            </Label>
                            <Label>
                                <Input type="checkbox" name="showAppService" onChange={e => onViewOptsChange(e)} />{' '}
                            App Service
                            </Label>
                            <Label>
                                <Input type="checkbox" name="showStartDate" onChange={e => onViewOptsChange(e)} />{' '}
                            Start Date
                            </Label>
                            <Label>
                                <Input type="checkbox" name="showItemHost" onChange={e => onViewOptsChange(e)} />{' '}
                            Host
                            </Label>
                        </FormGroup>
                        }
                        {(checkStatus.current > 0) && <div>Checking...{checkStatus.current} of {checkStatus.total}</div>}

                    </Row>
                </Form>

                {clientItems && versionList &&
                    <Table className="table-analysis" bordered={true} striped={true} size='sm' >
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                {versionList.map(v => (<th key={v} className={'center'}>{cleanVersion(v)}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {clientItems.map(c => {
                                return (<tr key={c}>
                                    <td>{c}<br />
                                        <img src={logoUrl(c)} style={{ width: '100px' }} alt="logo" />
                                    </td>
                                    {versionList.map(v => {
                                        return (
                                            <td key={v} className='cutt-off'>{mapUrls(c, v)}</td>
                                        )
                                    })}
                                </tr>)
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>&nbsp;</th>
                                {versionList.map(v => (<th key={v} className={'center'}>{cleanVersion(v)}</th>))}
                            </tr>
                        </tfoot>
                    </Table>
                }
                {/* <hr /> */}
                {/* <ReactJson src={viewOpts} /> */}
                {/* <hr /> */}
            </Container>

        </div>
    )
}

export default VersionGrid;
