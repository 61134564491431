import * as React from "react";
import { useState, useContext, useEffect } from 'react';
import * as objectPath from 'object-path';
import {
    // setUserState,
    searchUrls,
    checkUrl,
    deleteUrl
} from '../../stitch';
import { Container, Table, Input, Button, Badge } from 'reactstrap';
import { FaSearch, FaSync, FaTrash, FaSpinner } from 'react-icons/fa';
import SortHeader from 'src/components/SortHeader';
import { AuthUserContext } from '../../firebase/AuthUserContext';
import TimeAgo from 'react-timeago';
import userState from 'src/firebase/user-state';
// import enStrings from 'react-timeago/lib/language-strings/en';
// import { buildFormatter } from 'react-timeago/lib/formatters/buildFormatter';

// const myStrings = {
//     // ...enStrings,
//     suffixFromNow: "",
//     prefixFromNow: "in",
//     seconds: 'secs',
//     minutes: '%d min',
//     minute: '1 min',
//     hours: '%d hours',
//     hour: '1 hour',
//     month: '1 month',
//     months: '% months',
//     years: '% years',
//     year: '1 year',
//     suffixAgo: ""
// }


const VersionSearch = () => {
    const currentUser = useContext(AuthUserContext);

    const pageSizes = [10, 25, 50, 100, 250, 500];
    const STATE_KEY = 'version-search/urlSearchCrit';

    // const loadClients = async () => {
    // const list: any = await clients.find({}, { sort: { '_id': 1 } }).toArray();
    // setClientList(list);
    // }

    // const buildFormatter = (s: any) => {
    // return s;
    // }
    // const myFormatter = buildFormatter(myStrings);

    const columnOpts = [
        { key: "no", sort: false, text: '', name: '' },
        { key: "stage", sort: true, text: 'Stage', name: 'stage', style: { width: '70px' } },
        { key: "appGroup", sort: true, text: 'Group', name: 'appGroup' },
        { key: "category", sort: true, text: 'Cat', name: 'Category' },
        { key: "client", sort: true, text: 'Client', name: 'client' },
        { key: "url", sort: true, text: 'Url', name: 'url' },
        { key: "urlHdr", sort: false, text: '', name: '' },
        { key: "status", sort: true, text: 'Status', name: 'checkRes.status' },
        { key: "dbp", sort: true, text: 'Dbp', name: 'checkRes.dbp' },
        { key: "gw", sort: true, text: 'GW', name: 'checkRes.gw', style: { width: '60px' } },
        { key: "appService", sort: true, text: 'Service', name: 'checkRes.appService' },
        { key: "host", sort: true, text: 'Host', name: 'checkRes.host' },
        { key: "name", sort: true, text: 'App Name', name: 'checkRes.name' },
        // { key: "bigV", sort: true, text: 'V', name: 'bigV', style: { width: '40px' } },
        { key: "version", sort: true, text: 'Ver', name: 'version', style: { width: '70px' } },
        { key: "responseTimeMs", sort: true, text: 'Millies', name: 'checkInfo.responseTimeMs', style: { width: '70px' } },
        // { key: "checkStart", sort: true, text: 'Date', name: 'checkInfo.checkStart', style: { width: '70px' } },
        { key: "upTime", sort: true, text: 'Uptime', name: 'checkRes.upTime' },
        { key: "reqCnt", sort: true, text: 'Reqs', name: 'checkRes.reqCnt' },
        // { key: "country", sort: true, text: 'Ctry', name: 'ipInfo.country', style: { width: '30px' } },
        // { key: "regionCode", sort: true, text: 'Reg', name: 'ipInfo.region_code', style: { width: '30px' } },
        // { key: "city", sort: true, text: 'City', name: 'ipInfo.city' },
        // { key: "mongoBase", sort: true, text: 'Mongo', name: 'mongoBase' },
        // { key: "dbNamePrefix", sort: true, text: 'Db', name: 'dbNamePrefix', style: { width: '30px' } },
        // { key: "date", sort: true, text: 'Start', name: 'date', style: { width: '40px' } },
        // { key: "lastDate", sort: true, text: 'Seen', name: 'lastDate' },
        // { key: "requestCnt", sort: true, text: 'Req', name: 'requestCnt', style: { width: '50px' }, btnClass: "center" },
        // { key: "lastRequest", sort: true, text: 'Last', name: 'lastRequest' },
        // { key: "nodeVer", sort: true, text: 'Node', name: 'nodeVer' },
        // { key: "branch", sort: true, text: 'Git', name: 'git.branch' },
        // { key: "srcDate", sort: false, text: 'Src Date', name: '' },
        // { key: "srcUser", sort: false, text: 'Src User', name: '' },
        // { key: "", sort: false, text: '', name: '' },
    ];

    const initialUrls: any[] = [];

    const [urls, setUrls] = useState(initialUrls);
    const [qry, setQry] = useState({
        limit: 25,
        page: 1
    });
    const [sortOpts, setSortOpts] = useState({
        sortBy: 'id',
        sortDir: 1
    });
    const [checkers, setCheckers] = useState({});
    const [detailIds, setDetailIds] = useState({});

    const columnDefs = columnOpts; //.filter(c => !ignoreColumns[c.key]);

    useEffect(() => {
        userState.recallState(currentUser.uid, `${STATE_KEY}`).then(qry => {
            console.log(qry);
            if (qry) {
                setQry(qry);
                doSearch(qry)
            }
        })
    }, [currentUser])

    const onSort = (val: string) => {
        const newSortDir = sortOpts.sortDir * -1;
        console.log('onsort', val, 'dir:', newSortDir);
        urls.sort((a, b) => {
            const valA = objectPath.get(a, val, '1');
            const valB = objectPath.get(b, val, '2');
            return (valA > valB) ? newSortDir : -newSortDir;
        });
        setSortOpts({
            ...sortOpts,
            sortDir: newSortDir,
            sortBy: val
        });
        setUrls([...urls]);
    }

    const onSearch = () => {
        userState.rememberState(currentUser.uid, STATE_KEY, qry, true);
        return doSearch(qry);
    }

    const doSearch = (qry: any) => {
        return searchUrls(qry).then((res: any[]) => {
            setUrls(res);
        })
    }

    const handleInputChange = (event: any) => {
        const { target } = event;
        const { name } = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setQry({ ...qry, [name]: value });
    };

    const checkAll = async () => {
        const promises: Promise<any>[] = [];
        for (const u of urls) {
            promises.push(onCheckUrl(u));
        }
        Promise.all(promises).then(() => {
            onSearch();
        });
    }

    const onCheckAll = () => {
        return checkAll();
    }

    const onCheckUrl = (url: any) => {
        // console.log("checking", url.key);
        setCheckers({
            ...checkers,
            [url.key]: true
        });
        return checkUrl(url.key).then(res => {
            if (!res) {
                console.log("no res, ", url.key, res);
                return;
            }
            console.log("done checking", res.key);

            // find the item in the array
            const updatedItems = urls.map(u => {
                if (u.key === url.key) {
                    return {
                        ...u,
                        ...res
                    }
                } else {
                    return u
                }
            });
            setCheckers({
                ...checkers,
                [url.key]: false
            });
            setUrls(updatedItems);
        }).catch(err => {
            console.log("error checking:", err);
        })
    }

    const onShowDetail = (url: any) => {
        setDetailIds({
            ...detailIds,
            [url.key]: !detailIds[url.key]
        });
    }

    const onDeleteUrl = (url: any) => {
        if (!window.confirm("Delete url?" + url.key)) return;
        console.log(url);
        deleteUrl(url).then((out: any) => {
            const newUrls = urls.filter(u => {
                return (u._id !== url._id);
            })
            setUrls(newUrls);
        })
    }


    const statusBadge = (checkInfo: any) => {
        if (!checkInfo) return;
        let variant = 'danger';
        switch (checkInfo.statusCode) {
            case 200: variant = 'success'; break;
            default:
                break;
        }
        return (
            <Badge variant={variant}>{checkInfo.statusCode}</Badge>
        )
    }

    const searchField = (name: string, width?: number) => {
        const style = {};
        if (width) {
            style['width'] = width + 'px';
        }
        return (
            <Input
                // size="sm"
                type="text"
                style={style}
                name={name}
                value={qry[name] || ""}
                onChange={handleInputChange} />
        )
    }

    const makeGet = (url: any, hdr: any) => {
        return url.url + url.get + (hdr ? "?incl-headers=SrEDAeH" : "")
    }

    const noWrapStyle = { whiteSpace: "nowrap" } as React.CSSProperties;

    return (
        <div className='ml-2 mr-2 mt-3'>
            <Container fluid={true}>
                <h1>Version Search</h1>
                {/* <select onChange={e => setClient(e.target.value)}>
                    {clientList && clientList.map((c: any) => { return (<option key={c._id}>{c._id}</option>) })}
                </select> */}
                {/* Client: {client} */}

                <Table size="sm" striped bordered hover style={{ fontSize: '12px' }}>
                    <thead>
                        <tr>
                            <td></td>
                            <td> {searchField('stage', 60)} </td>
                            <td> {searchField('appGroup', 70)} </td>
                            <td> {searchField('category', 50)} </td>
                            <td style={{ maxWidth: '100px' }}>
                                {searchField('client', 90)}
                            </td>
                            <td> {searchField('url')} </td>
                            <td></td>
                            <td> {searchField('checkInfo.statusCode', 50)} </td>
                            <td></td>
                            <td> {searchField('checkRes.gw', 70)}</td>
                            <td> {searchField('checkRes.appService')} </td>
                            <td> {searchField('checkRes.host', 100)} </td>
                            <td> {searchField('checkRes.name', 100)}</td>
                            {/* <td> {searchField('checkRes.bigV', 30)}</td> */}
                            <td></td>
                            <td></td>
                            <td>
                                Size:<br />
                                <select value={qry.limit}
                                    name="limit"
                                    onChange={handleInputChange}>
                                    {pageSizes.map(ps => {
                                        return (<option key={ps}>{ps}</option>)
                                    })}
                                </select>
                            </td>
                            <td>
                                {/* Page:<br/>
                            <select value={qry.page}
                                name="page"
                                onChange={handleInputChange}>
                                {pages.map(ps => {
                                    return (<option key={ps}>{ps}</option>)
                                })}
                            </select> */}

                            </td>
                            <td>
                                <Button
                                    color="primary"
                                    onClick={() => onSearch()}>
                                    <FaSearch />
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Stage</th>
                            <th>Group</th>
                            <th title="Category">Cat</th>
                            <th>Client</th>
                            <th>Url</th>
                            <th></th>
                            <th>Status</th>
                            <th>Dbp</th>
                            <th>GW</th>
                            <th>Service</th>
                            <th>Host Name </th>
                            <th>App Name</th>
                            {/* <th>V</th> */}
                            <th>Ver</th>
                            <th>MS</th>
                            <th>Uptime</th>
                            <th>Reqs</th>
                            <th>Check</th>
                        </tr>

                        <tr className="smallest">
                            {columnDefs.map((f: any, i) => {
                                return (
                                    <th key={`ftr-${i}`} className={f.btnClass}>
                                        {f.sort ?
                                            <SortHeader text={f.text}
                                                fldName={f.name}
                                                sortBy={sortOpts.sortBy}
                                                sortDir={sortOpts.sortDir}
                                                title={f.name}
                                                onHeaderSort={(val) => onSort(val)} />
                                            : <span>{f.text}</span>
                                        }
                                    </th>
                                )
                            })}
                            <th>
                                <Button onClick={() => onCheckAll()}>
                                    <FaSync />
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {urls.map((u, index) => {
                            return (
                                <tr key={u._id}>
                                    <td title={u.key}>
                                        <Button
                                            title="Show Info"
                                            variant="dark"
                                            size="sm"
                                            onClick={() => onShowDetail(u)}
                                        > {index + 1}</Button>
                                    </td>
                                    <td>{u.stage}</td>
                                    <td>{u.appGroup}</td>
                                    <td>{u.category}</td>
                                    <td style={{ 'maxWidth': '100px' }}>{u.client}</td>
                                    <td>

                                        <a href={u.url + u.get}>{u.url + u.get}</a>
                                        {u.checkRes && u.checkRes.errMsg && <div><em>{u.checkRes.errMsg}</em></div>}
                                        {detailIds[u.key] && <div>{u.key}</div>}
                                    </td>

                                    <td>
                                        <a href={makeGet(u, true)} target="_blank" rel="noopener noreferrer">Hdr</a>
                                    </td>
                                    <td>{statusBadge(u.checkInfo)}
                                        &nbsp;
                                        {u.checkInfo &&
                                            <TimeAgo date={u.checkInfo.checkStart}
                                                // formatter={myFormatter}
                                            />}
                                    </td>
                                    <td>{u.checkRes && u.checkRes.dbp}</td>
                                    <td>{u.checkRes && u.checkRes.gw}</td>
                                    <td>{u.checkRes && u.checkRes.appService}</td>
                                    <td>{u.checkRes && u.checkRes.host}</td>
                                    <td>{u.checkRes && u.checkRes.name}</td>
                                    {/* <td>{u.checkRes && u.checkRes.bigV}</td> */}
                                    <td>{u.checkRes && u.checkRes.version}</td>
                                    <td>{u.checkInfo && u.checkInfo.responseTimeMs}</td>
                                    <td>{u.checkRes && u.checkRes.upTime}</td>
                                    <td>{u.checkRes && u.checkRes.reqCnt}</td>

                                    <td style={noWrapStyle}>
                                        {!checkers[u.key] && <div>
                                            <Button
                                                title="Check this url status"
                                                variant="dark"
                                                size="sm"
                                                onClick={() => onCheckUrl(u)}
                                            > <FaSync size={10} /></Button>
                                            <Button
                                                title="Delete this url"
                                                variant="dark"
                                                size="sm"
                                                onClick={() => onDeleteUrl(u)}
                                            > <FaTrash size={10} /></Button>
                                        </div>
                                        }
                                        {checkers[u.key] && <FaSpinner />}


                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default VersionSearch;