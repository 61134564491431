import { RemoteMongoClient, RemoteFindOptions } from "mongodb-stitch-browser-sdk";
import { app } from "./app";
import { IUrlQry } from "./interfaces";

const mongoClient = app.getServiceClient(
    RemoteMongoClient.factory,
    "mongodb-atlas"
);

const clients = mongoClient.db("wim").collection("clients");
const userStates = mongoClient.db("wim").collection("userStates");
const appGroups = mongoClient.db("wim").collection("appGroups");
const categories = mongoClient.db("wim").collection("categories");
const ecMappings = mongoClient.db("wim").collection("ecMappings");
const urls = mongoClient.db("wim").collection("urls");

const searchUrls = async (qry: IUrlQry): Promise<unknown[]> => {
    // return app.callFunction('searchUrls', [qry]) 
    // console.log(qry);
    const filter = {};
    const stringFlds = ['stage', 'appGroup', 'client', 'category', 'checkRes.bigV'];
    stringFlds.forEach(f => {
        if (qry[f]) {
            const crits = qry[f].split(",").map((s: string) => s.trim());
            if (crits.length > 1) {
                filter[f] = { $in: crits };
            } else {
                filter[f] = qry[f];
            }
        }
    });

    const intFlds = ['checkInfo.statusCode'];
    intFlds.forEach(f => {
        if (qry[f]) {
            const crits = qry[f].toString().split("|").map((s: string) => parseInt(s));
            if (crits.length > 1) {
                filter[f] = { $in: crits };
            } else {
                filter[f] = crits[0];
            }
        }
    });

    const matchFlds = [
        'url',
        'checkRes.gw',
        'checkRes.appService',
        'checkRes.host',
        'checkRes.name',
    ];
    matchFlds.forEach(f => {
        if (qry[f]) filter[f] = { $regex: qry[f] };
    });
    // console.log(filter);

    // const limit = parseInt(qry.limit) || 25;
    // const page = parseInt(qry.page || '1');
    // if (page <= 1) {
    //   qry.skip = 0;
    // } else {
    //   qry.skip = qry.limit * (page - 1);
    // }
    // console.log(qry);
    const opts: RemoteFindOptions = {
        ...qry.opts
    }

    const res = await urls.find(filter, opts);
    return res.asArray();
}

const checkUrl = (key:string) => {
    return app.callFunction('checkUrlFunc', [key])
  }

const getUserState = (userId: any, key: string) => {
    return userStates.findOne({ owner_id: userId }, { [key]: 1 });
}

const setUserState = (userId: any, key: string, data: any) => {
    const setter = {
        owner_id: userId,
        [key]: data
    };
    console.log(setter);
    // return userStates.findOneAndUpdate(
    //     { owner_id: userId },
    //     {
    //         $set: setter
    //     },
    //     {
    //         upsert: true
    //     }
    // );
}

const deleteUrl = (url:any) => {
    return urls.deleteOne({ _id: url._id });
  }

export {
    appGroups,
    categories,
    checkUrl,
    clients,
    deleteUrl,
    ecMappings,
    getUserState,
    setUserState,
    searchUrls
}