import * as React from "react";
import { Input } from 'reactstrap';
import { db } from "../../firebase/firebase";
import AppLogTable from './AppLogTable';
import { FilterTypes } from '../../components/Filters/FilterTypes';
import { Loading } from '../../components/Loading';
import { FaListAlt } from 'react-icons/fa';

type TTabNames = 'stage' | 'client';
type TPaths = 'v3/app-logs' | 'logs/v4';


interface IState {
    path: TPaths;
    activeTab: string;
    stages: string[];
    activeStage: string;
    clients: string[];
    activeClient: string;
    groups: string[];
    activeAppGroup: string;
    listLength: number;
    logs: any[];
    showJson: boolean;
}

interface IQuery {
    path: TPaths;
    limit: number;
    orderBy: string;
    equalTo: string;
}

export class AppLogs extends React.Component<{}, IState> {

    appLogsRef: any; //firebase.database.Query;
    query: IQuery;

    constructor(props: any) {
        super(props);

        this.state = {
            path: "logs/v4",
            activeTab: 'stage',
            stages: [],
            activeStage: '(all)',
            clients: [],
            activeClient: '(all)',
            groups: [],
            activeAppGroup: '(all)',
            listLength: 25,
            logs: [],
            showJson: false,
        };

        this.query = {
            path: "logs/v4",
            limit: 25,
            orderBy: 'date',
            equalTo: '',
        }
    }

    public componentDidMount() {
        this.loadLogs();
        this.loadKeys('stages', 'stages');
        this.loadKeys('groups', 'groups');
        this.loadKeys('clients', 'clients');
    }

    loadKeys(keyName: string, stateName: string) {
        db.ref(`v4/${keyName}`)
            .orderByKey()
            .once('value', async snap => {
                if (!snap) return;
                const list: string[] = ['(all)'];
                await snap.forEach(childSnapShot => {
                    list.push(childSnapShot.key || '');
                });
                const st: any = {
                    [stateName]: list
                }
                this.setState(st);
            });
    }

    componentWillUnmount() {
        if (this.appLogsRef)
            this.appLogsRef.off();
    }

    onChangePath(event: any) {
        const newPath = event.target.value;
        if (newPath !== this.query.path) {
            this.setState({
                path: newPath
            })
            this.query.path = newPath;
            this.loadLogs();
        }
    }

    onChangeLength(event: any) {
        this.query.limit = parseInt(event.target.value);
        this.setState({
            listLength: this.query.limit
        })
        this.loadLogs();
    }

    loadLogs() {

        if (this.appLogsRef)
            this.appLogsRef.off();

        this.appLogsRef = db.ref(this.query.path)
            .orderByChild(this.query.orderBy)
            .limitToLast(this.query.limit);

        if (this.query.equalTo) {
            this.appLogsRef = this.appLogsRef.equalTo(this.query.equalTo);
        }

        // console.log(this.query);
        this.appLogsRef.on('value', (snap:any) => {
            const list: any[] = [];
            if (snap) {
                snap.forEach((childSnapshot:any) => {
                    list.push({
                        key: childSnapshot.key,
                        ...childSnapshot.val()
                    })
                });
                list.sort((a: any, b: any) => {
                    return (a.date < b.date) ? 1 : -1;
                });
                this.setState({
                    logs: list
                });
            }
        });
    }

    toggleFilterType(tab: TTabNames) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    filterSelected(name: string, val: string) {
        let newState: any = {};
        switch (name) {
            case 'stage': newState['activeStage'] = val; break;
            case 'client': newState['activeClient'] = val; break;
        }
        this.setState(newState);

        const isAll = /all/i.test(val);
        this.query.orderBy = isAll ? 'date' : name;
        this.query.equalTo = isAll ? '' : val;
        this.loadLogs();
    }

    toggleDetail = () => {
        this.setState({ showJson: !this.state.showJson });
    } 


    public render() {
        return (
            <div className="ml-2 mr-2 mt-3">
                <div className="clearfix">
                    <div className="float-left">
                        <h2> <FaListAlt size="25" /> Applications start log</h2>
                    </div>
                    <div className="float-right">
                        Path: <Input type="select"
                            value={this.state.path}
                            name="select"
                            id="listLengthSelect"
                            onChange={event => this.onChangePath(event)}>
                            <option value="logs/v4">V4</option>
                            {/* <option value="v3/app-logs">V3</option> */}
                        </Input>
                    </div>
                    <div className="float-right">
                        Size: <Input type="select"
                            value={this.state.listLength}
                            name="select"
                            id="listLengthSelect"
                            onChange={event => this.onChangeLength(event)}>
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>250</option>
                            <option>500</option>
                        </Input>
                    </div>
                </div>
                <div>
                    <FilterTypes
                        stages={this.state.stages}
                        // clients={this.state.clients}
                        // groups={this.state.groups}
                        activeTab={this.state.activeTab}
                        activeStage={this.state.activeStage}
                        activeClient={this.state.activeClient}
                        activeAppGroup={this.state.activeAppGroup}
                        selectFilter={(name: string, val: string) => this.filterSelected(name, val)}
                        toggleTab={(tab: any) => this.toggleFilterType(tab)} />
                </div>
                {this.state.logs.length > 0 && <div>
                    <AppLogTable
                        logs={this.state.logs}
                        showJson={this.state.showJson}
                        toggleJson={this.toggleDetail}
                    />
                </div>}
                {this.state.logs.length === 0 && <Loading />}
            </div>
        );
    }

};