import * as React from "react";
import * as objectPath from 'object-path';
import { db } from "../../firebase/firebase";
import EndPointsTable from './EndPointTable';
import { FilterTypes } from '../../components/Filters/FilterTypes';
import { FaLink } from 'react-icons/fa';
import Input from 'reactstrap/lib/Input';
import userState from '../../firebase/user-state';
import { sayHallo } from '../../firebase/functions';
import Button from 'reactstrap/lib/Button';
import { Container, Col } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
// import { Nothing } from '../../components/Nothing';

const ENDPOINTS_PATH = 'v4/end-points';

type TTabNames = 'stage' | 'client';


interface IState {
    activeTab: string;
    stages: string[];
    activeStage: string;
    clients: string[];
    activeClient: string;
    groups: string[];
    activeAppGroup: string;
    listLength: number;
    filterText: string;
    filterData: any;
    endPoints: any[];
    infos: any;
    showJson: boolean;
}

interface IQuery {
    limit: number;
    orderBy: string;
    equalTo: string;
}

interface IProps {
    authUser: any;
}

export class EndPoints extends React.Component<IProps, IState> {

    endPointsRef: any; // firebase.database.Query;
    query: IQuery;
    sortDir: number = 1;
    filterVal: string = '';
    list: any[] = [];
    user: any;

    constructor(props: any) {
        super(props);
        // console.log(props);
        this.user = props.authUser;

        this.state = {
            activeTab: 'stage',
            stages: [],
            activeStage: '(all)',
            clients: [],
            activeClient: '(all)', //this.clients[0],
            groups: [],
            activeAppGroup: '(all)',
            listLength: 200,
            filterText: "",
            filterData: {},
            endPoints: [],
            infos: {},
            showJson: false,
        };

        this.query = {
            limit: 200,
            orderBy: this.state.activeTab,
            equalTo: '',
        }
    }

    public componentDidMount() {
        this.loadState().then(() => {
            this.loadEndPoints();
            this.loadKeys('stages', 'stages');
            this.loadKeys('groups', 'groups');
            this.loadKeys('clients', 'clients');
        })
    }

    componentWillUnmount() {
        if (this.endPointsRef) this.endPointsRef.off();
    }

    async loadState() {
        userState.recallState(this.user.uid, 'end-points').then(lastState => {
            if (lastState) {
                this.sortDir = lastState.sortDir || 1;
                this.query.orderBy = lastState.sortBy || this.state.activeTab;
                if (lastState.filterData) {
                    const newFilter = JSON.parse(lastState.filterData);
                    this.setState({
                        filterData: newFilter
                    });
                }
            }
        })
        // return db.ref(`v4/userState/${this.user.uid}/end-points`)
        //     .once('value')
        //     .then(snap => {
        //         if (snap) {
        //             // const lastState: IUserChoices = snap.val() || {};
        //             const lastState: any = snap.val() || {};
        //             this.sortDir = lastState.sortDir || 1;
        //             this.query.orderBy = lastState.sortBy || this.state.activeTab;
        //             // setSortBy(lastState.sortBy || 'appName');
        //             // setSortDir(lastState.sortDir || 1);
        //             // setFilterText(lastState.filterText || '');
        //             // setFilterData(lastState.filterData ? JSON.parse(lastState.filterData) : {});
        //             if (lastState.filterData) {
        //                 const newFilter = JSON.parse(lastState.filterData);
        //                 this.setState({
        //                     filterData: newFilter
        //                 });
        //             }
        //         }
        //     });
    }

    loadKeys(keyName: string, stateName: string) {
        db.ref(`v4/${keyName}`)
            .orderByKey()
            .once('value', async snap => {
                if (!snap) return;
                const list: string[] = ['(all)'];
                await snap.forEach(childSnapShot => {
                    list.push(childSnapShot.key || '');
                });
                const st: any = {
                    [stateName]: list
                }
                this.setState(st);
            });
    }

    loadEndPoints() {

        if (this.endPointsRef)
            this.endPointsRef.off();

        const ref = db.ref(ENDPOINTS_PATH)
            .orderByChild(this.query.orderBy);

        if (this.query.equalTo) {
            this.endPointsRef = ref
                .equalTo(this.query.equalTo)
                .limitToFirst(this.query.limit);
        } else {
            this.endPointsRef = ref
                .limitToFirst(this.query.limit);
        }


        this.endPointsRef.on('value', async (snap: any) => {
            if (!snap) return;

            this.list = [];
            await snap.forEach((childSnapShot: any) => {
                const key = childSnapShot.key || '';
                this.list.push({
                    key: key,
                    ...childSnapShot.val()
                });
            });
            this.applySort(this.state.filterText);
        });
    }

    applySort(filterText: string) {
        // filter first 
        let list: any[] = [...this.list];

        if (filterText) {
            list = this.list.filter(item => {
                return JSON.stringify(item).match(filterText);
            })
        }

        const { filterData } = this.state;
        const filteredKeys = Object.keys(filterData).filter(k => filterData[k]);
        if (filteredKeys.length > 0) {
            list = list.filter(item => {
                const found = filteredKeys.every(key => {
                    const itemValue = objectPath.get(item, key, '').toString();
                    if (itemValue) {
                        const filterValue = RegExp((filterData[key] || '').toString(), 'i');
                        return !!(itemValue.match(filterValue));
                    } else {
                        return false;
                    }
                });
                return found;
            })
        }

        const orderBy = this.query.orderBy;
        list.sort((a: any, b: any) => {
            const valA = objectPath.get(a, orderBy, '1');
            const valB = objectPath.get(b, orderBy, '2');
            return (valA > valB) ? this.sortDir : -this.sortDir;
        });

        this.setState({
            filterText: filterText,
            endPoints: list
        });
    }

    rememberChoice = (key: string, val: any) => {
        if (!this.user || !this.user.uid) return;
        return userState.rememberState(this.user.uid, `end-points/${key}`, val);
    }

    toggleFilterType(tab: TTabNames) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    filterSelected(name: string, val: string) {
        let newState: any = {};
        switch (name) {
            case 'stage': newState['activeStage'] = val; break;
            case 'client': newState['activeClient'] = val; break;
            case 'appGroup': newState['activeAppGroup'] = val; break;
        }
        this.setState(newState);

        const isAll = /all/i.test(val);
        this.query.orderBy = isAll ? 'date' : name;
        this.query.equalTo = isAll ? '' : val;
        this.loadEndPoints();

    }

    onSort(orderBy: string) {
        if (this.query.orderBy === orderBy) {
            this.sortDir = this.sortDir * -1;
            this.rememberChoice('sortDir', this.sortDir);
        } else {
            this.query.orderBy = orderBy;
            this.rememberChoice('sortBy', orderBy);
        }
        this.applySort(this.state.filterText);
    }

    onFilter(event: any) {
        const val = event.target.value;
        this.rememberChoice('filterValue', val);
        this.applySort(val);
    }

    onFilterFld(event: any) {
        const { name, value } = event.target;
        const newFilter = {
            ...this.state.filterData,
            [name]: value
        }
        Object.keys(newFilter).forEach((key) => (!newFilter[key] && delete newFilter[key]));
        this.rememberChoice('filterData', newFilter);
        this.setState({
            filterData: newFilter
        }, () => {
            this.applySort(this.state.filterText);
        });
    }

    deleteEndPoint(epId: string) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Delete endpoint')) return;
        db.ref(ENDPOINTS_PATH).child(epId).remove();
    }

    toggleDetail = () => {
        this.setState({ showJson: !this.state.showJson });
    }

    checkEndPoint(epId: string) {
        const ep = this.list.find(item => item.key === epId);
        if (ep) {
            db.ref(ENDPOINTS_PATH).child(epId).update({ checkNow: !ep.checkNow });
        }
    }

    showInfo(epId: string) {
        this.setState({
            infos: {
                ...this.state.infos,
                [epId]: !this.state.infos[epId]
            }
        })
    }

    clearStaleApps() {
        sayHallo().then(out => {
            console.log(out);
        })
    }

    public render() {
        return (
            <div className="ml-2 mr-2 mt-3">
                <Container fluid={true}>
                    <Row>
                        <Col xs="10">
                            <h2><FaLink size="25" /> End Points</h2>
                        </Col>
                        <Col xs="2" className="right">
                            <Button onClick={this.clearStaleApps} title="Some small test">Test</Button>
                        </Col>
                    </Row>
                </Container>
                <div className="pl-3 pr-3">
                    <FilterTypes
                        stages={this.state.stages}
                        clients={this.state.clients}
                        groups={this.state.groups}
                        activeTab={this.state.activeTab}
                        activeStage={this.state.activeStage}
                        activeClient={this.state.activeClient}
                        activeAppGroup={this.state.activeAppGroup}
                        selectFilter={(name: string, val: string) => this.filterSelected(name, val)}
                        toggleTab={(tab: any) => this.toggleFilterType(tab)} />
                    <Input type="text" value={this.state.filterText} placeholder="Quick filter" onChange={(e) => this.onFilter(e)} />
                </div>
                <EndPointsTable
                    list={this.state.endPoints}
                    sortBy={this.query.orderBy}
                    sortDir={this.sortDir}
                    onSort={(val: string) => this.onSort(val)}
                    onDelete={(id: string) => this.deleteEndPoint(id)}
                    onCheck={(id: string) => this.checkEndPoint(id)}
                    infos={this.state.infos}
                    onInfo={(id: string) => this.showInfo(id)}
                    onFilterFld={(e: any) => this.onFilterFld(e)}
                    filterData={this.state.filterData}
                    showJson={this.state.showJson}
                    toggleJson={this.toggleDetail}
                />
                {/* {this.state.endPoints.length == 0 && !this.state.filterText && <Loading />} */}
                {/* {this.state.endPoints.length == 0 && this.state.filterText && <Nothing />} */}
            </div>
        )
    }

}