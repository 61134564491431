import * as React from "react";
import { Link } from "react-router-dom";
import {
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink
} from 'reactstrap';
import * as routes from "../constants/routes";
import { SignOutButton } from "./SignOutButton";
export const NavMenu = (props: any) => {
    return (
        <Navbar color="dark" dark={true} expand="md">
            <NavbarBrand to={routes.HOME} tag={Link}>
                <img alt="logo" src="/img/logo.png" className="nav-logo" />
                It's WARM, don't over<strong>React</strong>
            </NavbarBrand>

            {/* <NavbarToggler onClick={this.toggle} /> */}

            <Nav pills={true} className="ml-auto" navbar={true}>
                <NavItem>
                    <NavLink active={window.location.pathname === routes.VERSION_SEARCH} tag={Link} to={routes.VERSION_SEARCH}>Search</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={window.location.pathname === routes.VERSION_GRID} tag={Link} to={routes.VERSION_GRID}>Grid</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={window.location.pathname === routes.ENDPOINTS} tag={Link} to={routes.ENDPOINTS}>Endpoints</NavLink>
                </NavItem>
                <NavItem active={window.location.pathname === routes.APPINSTANCES}>
                    <NavLink active={window.location.pathname === routes.APPINSTANCES} tag={Link} to={routes.APPINSTANCES}>Apps</NavLink>
                </NavItem>
                <NavItem >
                    <NavLink active={window.location.pathname === routes.APPLOGS} tag={Link} to={routes.APPLOGS}>Logs</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={window.location.pathname === routes.ACCOUNT} tag={Link} to={routes.ACCOUNT}>Account</NavLink>
                </NavItem>
                <NavItem>
                    <SignOutButton />
                </NavItem>
                <NavItem>
                    <NavLink>{process.env.REACT_APP_VERSION}</NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    );
}